<template>
  <div class="vh-100 w-100 d-flex justify-content-center align-items-center" style="background:#F8F8F8;">
      <div class="custom-card p-4">
          <div style="font-size:19px;" class="mb-1">
              Hey <span><b>{{this.$route.query.name}}</b></span> 👋 
          </div>
          <div style="font-size:19px;">
              Thanks for signing up!
          </div>
          <div class="position-relative d-flex justify-content-center my-4">
              <img :src="Image" alt="">
          </div>
          <div style="font-size:19px;">
              Please check you mail to verify your account
          </div>
          <div class="d-flex justify-content-start align-items-center my-3">
              <button type="button" class="verify-button" @click="useVerifyEmail">Resend Link</button>
              <!-- <div class="resend-email mx-3">
                  Resend Link
              </div> -->
          </div>
          <div class="mt-3">
              <div>Already verified? <a href="/pages/newLogin" style="color:#8D83FD;text-decoration:none;"><b>Login</b></a></div>
          </div>
      </div>
  </div>
</template>

<script>
import { verifyEmailBeta } from "@/utils/api.js";
import Image from '@/assets/images/illustration/pricing-Illustration.svg';

export default {
    data(){
        return {
            Image,
        }
    },
   methods: {
       async useVerifyEmail(){           
           try{let res= await verifyEmailBeta({email: this.$route.query.email});
           console.log(res.data)}
           catch(err){
               console.log(err)
           }
       }
   }
}
</script>

<style lang="scss">
    .custom-card{
        width: 600px;
        min-height: 569px;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        max-width: 95%;
        color:#5E5873;
    }
    .verify-button{
        background-color: #4256EE;
        color: #fff;
        border: none;
        padding: 10px 25px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
    }
    .resend-email{
        color: #4256EE;
        font-size: 10px;
        cursor: pointer;
    }
</style>